.footer-heading {
    color: #0b95ad;
}

.Footer-Card {
    background: url("../../../Assets/Images/footer.jpg") no-repeat center;
    background-size: cover;
}

@media screen and (max-width: 1200px) {
    .Footer-Card {
        background: url("../../../Assets/Images/footer.jpg") no-repeat left;
    }
}

.footer-Col1-heading h3 {
    color: #0dcaf0;
    text-align: center;
    font-size: 28px;
    margin-bottom: 40px;
    position: relative
}

.footer-Col1-heading h3::before {
    content: "";
    position: absolute;
    bottom: -10px;
    height: 4px;
    width: 130px;
    background: #0083f5

}

.footer-Col2-heading h3 {
    color: #0dcaf0;
    text-align: center;
    font-size: 28px;
    margin-bottom: 40px;
    position: relative
}

.footer-Col2-heading h3::before {
    content: "";
    position: absolute;
    bottom: -10px;
    height: 4px;
    width: 90px;
    background: #0083f5
}

.footer-Col3-heading h3 {
    color: #0dcaf0;
    text-align: center;
    font-size: 28px;
    margin-bottom: 40px;
    position: relative
}

.footer-Col3-heading h3::before {
    content: "";
    position: absolute;
    bottom: -10px;
    height: 4px;
    width: 190px;
    background: #0083f5
}

.link-border {
    padding: 5px !important;
    border-radius: 15px !important;
    cursor: pointer;
}

.img-border {
    border-radius: 10px !important;
}

.copyright-section {
    background: #081224;
    padding: 25px 0
}

.copyright-text-section p {
    margin: 0;
    font-size: 14px;
    color: #878787
}

.copyright-text-section p a {
    color: #00bfd8;
    text-decoration: none
}

.copyright-text-section a:hover {
    color: #fff;
    text-decoration: none
}