.intro-main {
    background: #fff;
    background: linear-gradient(152deg, #fff 0, #00bfd8 42%, #0083f5 100%);
}

.intro-desc-text {
    color: #00bfd8
}

.hero-text h1 {
    font-size: 3.5rem;
    font-weight: 700
}

.hero-text h1 span {
    font-size: 3.5rem;
    font-weight: 500
}

@media all and (max-width: 992px) {
    .hero-text p {
        font-size: .9rem;
        padding: 0 2rem
    }

    .hero-text {
        text-align: center
    }
}