.card-img-top {
    width: 60%;
    border-radius: 50%;
    margin: 0 auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.card-main {
    width: 100%;
    padding: 1.5em 0.5em 0.5em;
    text-align: center;
    border-radius: 2em;
    background-color: rgba(255, 255, 255, 0.55);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.card-title {
    font-weight: bold;
    font-size: 1.5em;
}

.profile-img-side {
    background-image: url("../../../../../Assets/Images/dummy.png");
    background-color: #1b1b1b;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    height: 12rem;
    width: 12rem;
}

.footerSideMenu {
    padding: 0.25rem 0;
    border-radius: 0 0 1rem 1rem !important;
    margin: 0;
    background-color: #222222;
}

.skills-title {
    border: none;
    padding: 0.4rem 1rem;
    background-color: #324a57;
    border-radius: 0.4rem;
    color: #fff;
}

@media all and (min-width: 1200px) {
    .skills-title:hover {
        color: black;
        border: 1px solid #324a57;
        background-color: #fff;
        transition: all 0.5s ease;
    }
}

.card-body-main {
    height: 8rem;
}

.overMe {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.btn-main {
    background-color: #01B9DA;
    color: white;
    text-align: center;
    padding: 8px 10px;
    width: 8rem;
    border: 1px solid #FFFF;
    border-radius: 50px;
    transition: all .3s ease-in-out
}

.btn-main:hover {
    transform: translateX(6%)
}

.resume-title {
    border-radius: 20px;
    color: black;
    border: 1px solid #324a57;
    background-color: #fff;
    padding: 0.3rem 1rem;
    width: max-content;
}

@media all and (min-width: 1200px) {
    .resume-title:hover {
        color: white;
        background-color: #324a57;
        transition: all 0.5s ease;
    }
}