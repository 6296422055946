.pagination {
    display: flex;
    list-style: none;
    margin: 1rem 0;
}

.pagination a {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;
    color: #0083f5;
    margin: 0 0.5rem;
    height: 50px;
    width: 50px;
}

.page .pageLink {
    border: 2px solid #0083f5;
    border-radius: 50%;
}

.next a {
    background: url("../../../../../Assets/Images/pagination_next.svg") center no-repeat;
}

.previous a {
    background: url("../../../../../Assets/Images/pagination_prev.svg") center no-repeat;
}

.active_link {
    border: 2px solid #0083f5;
    border-radius: 50%;
    text-decoration: none;
}