@font-face {
    font-family: bariolreg;
    src: url(./Assets/fonts/bariol_regular-webfont.woff) format('woff');
    unicode-range: U+0020-007F
}

@font-face {
    font-family: bariolbold;
    src: url(./Assets/fonts/bariol_bold-webfont.woff) format('woff');
    unicode-range: U+0020-007F
}

body {
    font-family: bariolreg !important;
}

.heading-bold {
    font-family: bariolbold !important;
}