.navbar-main {
    z-index: 100;
    transition: .5s ease-in-out;
    height: 65px
}

.nav-bg {
    background-color: #f5f5f5
}

.book-slot {
    background-color: #01B9DA;
    color: white;
    text-align: center;
    padding: 8px 10px;
    width: 8rem;
    border: 1px solid #FFFF;
    border-radius: 50px;
    transition: all .3s ease-in-out
}

.book-slot:hover {
    transform: translateX(6%)
}

@media screen and (max-width: 576px) {
    .overflow-nav {
        z-index: 100;
    }

    .book-slot {
        font-size: 12px !important;
    }
}